import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Button } from 'reactstrap';
import { useProfile } from "../../Hooks/UserHooks"
import { getS3BaseUrl } from "../../../helpers/string_helper";
import { FileUpload } from '@mui/icons-material';
import FileList from "../../Common/FileList";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { updateProfile } from "../../../api/user";
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios/auth";
import { storeUserDetails } from "../../../store/auth";
import { logoutUser } from "../../../store/actions";
import { useDispatch } from "react-redux";
import { ROLES } from "../../../constants/variables";
import { AsterikLabel } from "../../Common/AsterikLabel";
import { TextField, Card, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

export default function ViewProfileDrawer({
	isOpen,
	toggleDrawer,
	setIsEditMode,
}) {
	const { user } = useProfile()
	const s3BaseUrl = getS3BaseUrl()
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch();
	const [isShowBankFrom, setIsShowBankFrom] = useState(false)
	const [selectedOption, setSelectedOption] = useState('upi');

	const bankDetailsSchema = {
		selectedOption: "upi",
		upi_id: user?.property_owner?.bank_detail?.upi_id,
		address: user?.address,
		email: user?.email,
		bank_detail: {
			account_holder_name: user?.property_owner?.bank_detail?.account_holder_name ?? '',
			account_number: user?.property_owner?.bank_detail?.account_number ?? '',
			bank_name: user?.property_owner?.bank_detail?.bank_name ?? '',
			ifsc: user?.property_owner?.bank_detail?.ifsc ?? '',
		}
	}
	const bankDetailsValition = Yup.object().shape({
		upi_id: Yup.string().when('selectedOption', {
			is: (data) => data === 'upi',
			then: () => Yup.string().required('Please enter upi id').matches(/^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/, 'Please enter valid upi id')
		}),
		bank_detail: Yup.object().when('selectedOption', {
			is: (data) => data === 'bank',
			then: () => Yup.object().shape({
				account_holder_name: Yup.string()
					.strict(true)
					.trim('Must not include leading and trailing spaces')
					.max(100, 'Too Long!')
					.required('Please enter account holder name'),
				account_number: Yup.string()
					.matches(/^\d{9,18}$/, 'Invalid account number')
					.required('Please enter account number'),
				bank_name: Yup.string()
					.strict(true)
					.trim('Must not include leading and trailing spaces')
					.max(100, 'Too Long!')
					.required('Please enter bank name'),
				ifsc: Yup.string()
					.strict(true)
					.trim('Must not include leading and trailing spaces')
					.matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC code')
					.required('Please enter ifsc code'),
			}),
		}),
		address: Yup.string()
			.strict(true)
			.trim('Must not include leading and trailing spaces')
			.max(100, 'Too Long!')
			.required('Please enter address'),
		email: Yup.string()
			.strict(true)
			.trim('Must not include leading and trailing spaces')
			.email()
			.typeError('Please Enter Valid Email')
			.required(),
	});

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: bankDetailsSchema,
		validationSchema: bankDetailsValition,
		validateOnMount: true,
		onSubmit: async () => {
			setIsLoading(true);
			const payload = {
				address: formik.values.address,
				email: formik.values.email,
				property_owner: {
                payment_mode: formik.values.selectedOption,
                ...(formik.values.bank_detail && selectedOption === 'bank' ? {
                    bank_detail: {
                        ...(formik.values.bank_detail.account_holder_name && {
                            account_holder_name: formik.values.bank_detail.account_holder_name,
                        }),
                        ...(formik.values.bank_detail.account_number && {
                            account_number: formik.values.bank_detail.account_number,
                        }),
                        ...(formik.values.bank_detail.ifsc && {
                            ifsc: formik.values.bank_detail.ifsc,
                        }),
                        ...(formik.values.bank_detail.bank_name && {
                            bank_name: formik.values.bank_detail.bank_name,
                        }),
                    },
                } : {
                    upi_id: formik.values.upi_id
                }),
				}
			};
			try {
				const response = await updateProfile(payload);
				if (response.status === 200) {
					toast.success(response.data.message);
					// Refreshing user token in redux
					axiosInstance.get('/user/me').then((response) => {
						dispatch(storeUserDetails({ user: response.data.data }))
					}).catch((err) => {
						console.error(err)
						dispatch(logoutUser());
					})
					setIsShowBankFrom(false)
				} else {
					toast.error(response.data.message);
				}
			} catch (error) {
				toast.error(error.response.data.message);
				console.log(error);
			}
			setIsLoading(false);
		},
	});

	const handleChange = (event) => {
		formik.resetForm()
		formik.setFieldValue('selectedOption', event.target.value)
		setSelectedOption(event.target.value);
	};


	const handleClose = () => {
		setIsEditMode && setIsEditMode(false);
		toggleDrawer(false);
	};

	return (
		<Modal isOpen={isOpen} direction="end" className="tenantDetailsDrawer">
			<div className="profileViewer">
				<ModalHeader toggle={handleClose}>
					View Profile Details
				</ModalHeader>
				<ModalBody>
					<div className='tenantDetailsDrawer'>
						<Row className='mb-5'>
							<div className='col-sm-12'>
								<div className='custom-image-upload'>
									{user?.profile_photo && (
										<div
											className="image-placeholder"
										>
											<img
												src={user?.profile_photo ? `${s3BaseUrl}${user?.profile_photo}` : FileUpload}
												alt={user.profile_photo}
											/>
										</div>
									)}
									<div className='upload_picture mx-3'>
										<div className='pro_person_name'>
											{user?.name}
											&nbsp;
											({user.code})
										</div>
										<div className='pro_person_mob'>
											<i className='fas fa-phone-alt me-1' />
											<span className='country_code'>+91</span>
											<span className='pr_person_contact'>
												{user?.contact_number ?? 'N/A'}
											</span>
										</div>
										<div className='pro_person_mob tenantDetailEmail'>
											<span className='pr_person_contact d-flex align-items-baseline'>
												<i className='fas fa-envelope me-1' />
												{user?.email ?? 'N/A'}
											</span>
										</div>
									</div>
								</div>
							</div>
						</Row>
						<Row className='mb-3'>
							<div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
								<div className='tenantDetailTitle'>Occupation :</div>
								<div className='tenantDetailDesc'>
									{user?.property_owner?.occupation ?? 'N/A'}
								</div>
							</div>

							<div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
								<div className='tenantDetailTitle'>Property Status :</div>
								<div className='tenantDetailDesc'>
									{user?.property_owner?.property_status ?? 'N/A'}
								</div>
							</div>
						</Row>

						{isShowBankFrom ? (
							<>
								<div className='d-flex justify-content-center mt-2'>
									<Card className="bedMatrixOptions" style={{ boxShadow: 'unset', marginLeft: '4px' }}>
										<FormControl>
											<RadioGroup
												row
												value={selectedOption}
												onClick={handleChange}
											>
												<FormControlLabel value="upi" control={<Radio color="default" />} label='UPI' style={{ color: 'black' }} />
												<FormControlLabel value="bank" control={<Radio color="default" />} label="BANK Account" style={{ color: 'black' }} />
											</RadioGroup>
										</FormControl>
									</Card>
								</div>
							</>
						) : (
							<Row className='mb-3 row'>
								<div className="col-sm-12 d-flex align-items-start custom_border_bottom pb-3">
									<div className="tenantDetailTitle" style={{ width: '25%' }}>Address:</div>
									<div className="tenantDetailDesc" style={{ width: '65%' }}>{user?.address}</div>
								</div>
							</Row>
						)}


						{/* Bank Details */}
						<Row className='mb-3'>
							{!isShowBankFrom && (
								user?.property_owner?.bank_detail?.bank_name ? (
									<div className='col-12 mb-3 row'>
									<div className='bankAccountDet'>
										<h5 className='mb-3'>Bank Account Details</h5>
									</div>
									<Row className='mb-3'>
										<div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
											<div className='tenantDetailTitle'>Bank Name :</div>
											<div className='tenantDetailDesc'>
												{user?.property_owner?.bank_detail?.bank_name ?? 'N/A'}
											</div>
										</div>

										<div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
											<div className='tenantDetailTitle'>IFSC :</div>
											<div className='tenantDetailDesc'>
												{user?.property_owner?.bank_detail?.ifsc ?? 'N/A'}
											</div>
										</div>
									</Row>
									<Row className='mb-3'>
										<div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
											<div className='tenantDetailTitle'>Account Name :</div>
											<div className='tenantDetailDesc'>
												{user?.property_owner?.bank_detail?.account_holder_name ?? 'N/A'}
											</div>
										</div>

										<div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
											<div className='tenantDetailTitle'>Account Number :</div>
											<div className='tenantDetailDesc'>
												{user?.property_owner?.bank_detail?.account_number ?? 'N/A'}
											</div>
										</div>
									</Row>
								</div>
								) : (
									<div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
											<div className='tenantDetailTitle'>UPI ID :</div>
											<div className='tenantDetailDesc'>
												{user?.property_owner?.bank_detail?.upi_id ?? 'N/A'}
											</div>
										</div>	
								)
								
							)}

							{isShowBankFrom &&
								<>
									{selectedOption === 'upi' ? (
										<Row>
											<div className='col-sm-12'>
												<TextField
													fullWidth
													id="outlined-multiline-static"
													label={<div>UPI ID <AsterikLabel /></div>}
													name="upi_id"
													value={formik.values?.upi_id}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													variant="outlined"
													size="small"
													className="w-100" />
												<div className='d-flex mb-3 mt-1'>
													{formik.touched.upi_id && formik.errors.upi_id && (
														<span className="text-danger">{formik.errors.upi_id}</span>
													)}
												</div>
											</div>
										</Row>
									) : (
										<Row>
											{/* <div className='col-12 mt-4'></div> */}
											<div className='col-sm-6 mb-3'>
												<TextField
													size='small'
													className='form-control'
													type='text'
													name='bank_detail.account_holder_name'
													label={<div>Account Holder Name <AsterikLabel /></div>}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values?.bank_detail?.account_holder_name || ''} />
												<div className='d-flex mt-1'>
													{formik.touched?.bank_detail?.account_holder_name && formik.errors.bank_detail?.account_holder_name && (
														<span className="text-danger">{formik.errors.bank_detail?.account_holder_name}</span>
													)}
												</div>
											</div>
											<div className='col-sm-6 mb-3'>
												<TextField
													size='small'
													className='form-control'
													type='text'
													name='bank_detail.account_number'
													label={<div>Account Number <AsterikLabel /></div>}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values?.bank_detail?.account_number || ''} />
												<div className='d-flex mt-1'>
													{formik.touched?.bank_detail?.account_number && formik.errors.bank_detail?.account_number && (
														<span className="text-danger">{formik.errors.bank_detail?.account_number}</span>
													)}
												</div>
											</div>
											<div className='col-sm-6 mb-3'>
												<TextField
													size='small'
													className='form-control'
													type='text'
													name='bank_detail.ifsc'
													label={<div>IFSC <AsterikLabel /></div>}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values?.bank_detail?.ifsc || ''} />
												<div className='d-flex mt-1'>
													{formik.touched?.bank_detail?.ifsc && formik.errors.bank_detail?.ifsc && (
														<span className="text-danger">{formik.errors.bank_detail?.ifsc}</span>
													)}
												</div>
											</div>
											<div className='col-sm-6 mb-3'>
												<TextField
													size='small'
													className='form-control'
													type='text'
													name='bank_detail.bank_name'
													label={<div>Bank Name <AsterikLabel /></div>}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values?.bank_detail?.bank_name || ''} />
												<div className='d-flex mt-1'>
													{formik.touched?.bank_detail?.bank_name && formik.errors.bank_detail?.bank_name && (
														<span className="text-danger">{formik.errors.bank_detail?.bank_name}</span>
													)}
												</div>
											</div>
										</Row>
									)}
									<Row>
										<div className='col-sm-6 mb-4'>
											<TextField
												size='small'
												className='form-control'
												type='text'
												name='email'

												label={<div>Email <AsterikLabel /></div>}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values?.email || ''} />
											<div className='d-flex mt-1'>
												{formik.touched?.email && formik.errors?.email && (
													<span className="text-danger">{formik.errors?.email}</span>
												)}
											</div>
										</div>
										<div className='col-sm-6 mb-4'>
											<TextField
												size='small'
												className='form-control'
												type='text'
												name='address'
												label={<div>Address <AsterikLabel /></div>}
												onBlur={formik.handleBlur}
												value={formik.values?.address || ''}
												onChange={formik.handleChange}
												disabled={isLoading}
												error={!!(
													formik.touched?.address &&
													formik.errors?.address
												)}
												helperText={formik.touched?.address &&
													formik.errors?.address
													? formik.errors?.address
													: ''} />
										</div>
									</Row>
								</>
							}

							{user.role.role === ROLES.OWNER &&
								(<div className='gen_leave_add_btn text-center col-sm-12 custom_border_bottom pb-3'>
									<Button
										className="yellow_gradient_btn mx-2"
										size="small"
										type={!isShowBankFrom ? 'button' : 'submit'}
										disabled={isLoading}
										onClick={() => {
											if (!isShowBankFrom)
												setIsShowBankFrom(true);
											else {
												formik.handleSubmit();
											}
										}}
									>
										{isShowBankFrom ? 'Save' : user?.property_owner?.bank_detail ? 'Edit Bank Details' : 'Add Bank Details'}
									</Button>
								</div>)}
						</Row>
						<Row className='mb-3'>
							<div className='col-sm-12 custom_border_bottom pb-3'>
								{
									user?.property_owner?.other_documents?.length > 0 && (
										<>
											<h5 className='mb-3'>Attachments</h5>
											<FileList files={user?.property_owner?.other_documents} />
										</>
									)
								}
							</div>
						</Row>
					</div>
				</ModalBody>
			</div>
		</Modal>
	);
}