export const PER_PAGE = [5, 10, 20, 50, 100];

export const DEFAULT_PER_PAGE_VALUE = 10;

export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'

export const DEFAULT_DATETIME_FORMAT = 'DD-MM-YYYY hh:mm A'

export const ACTIONS = {
    CREATE: 'Create',
    EDIT: 'Edit',
    VIEW: 'View',
    DELETE: 'Delete',
  };

export const PORTAL = {
    TENANT_PORTAL: 'Tenant Portal',
    BACKOFFICE_PORTAL: 'Backoffice Portal',
    OWNER_PORTAL: 'Owner Portal',
};

export const TENANT_TYPES = {
    OTHERS: 'Others',
    STUDENT: 'Student',
    WORKING_PROFESSIONAL: 'Working Professional',
}

export const BED_TYPES = {
    SINGLE: 'Single Sharing',
    DOUBLE: 'Double Sharing',
    TRIPLE: 'Triple Sharing',
};

export const ROOM_TYPES = {
  AC: 'AC',
  NON_AC: 'Non AC',
}

export const PROPERTY_TYPES = {
  APARTMENT: 'Apartment or Flat Style',
  PERSONAL_HOME: 'Non-Apartment Style',
};

export const ALL_MODULES = {
    TENANTS: 'Tenants',
    OWNERS: 'Owners',
    PROPERTIES: 'Properties',
    COMPLAIN_TICKETS: 'Complain Tickets',
    ADD_PROPERTY_REQUESTS: 'Add Property Requests',
    MESSAGE: 'Message',
    NOTIFICATION: 'Notification',
  }


export const OPERATION_TYPE = {
    CREATE: 'Create',
    UPDATE: 'Edit',
    DELETE: 'Delete',
    READ: 'Read',
    ADD_TENANT: 'Add Tenant',
    REMOVE_TENANT: 'Remove Tenant',
  }

  export const DOCUMENT_HASH_BASE_KEYS = {
    PROPERTY_REQUEST: 'property-request',
};

export const GENDER_PREFERENCE = {
  GIRLS_PG: `Girls' PG`,
  BOYS_PG: `Boys' PG`,
  COLIVE_PG: 'Colive PG'
}


