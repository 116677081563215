import React, { useEffect, useState } from "react";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from "dayjs";
import { getAllGlobalSettings } from "../../api/global-settings";

export default function PayoutHistoryDetails(){
    const navigate = useNavigate();
    const location = useLocation();
    const [payoutPercentage, setPayoutPercentage] = useState()
    const [payoutHistoryDetails, setPayoutHistoryDetails] = useState()

    useEffect(() => {
        if (location?.state?.payout_history) {
            setPayoutHistoryDetails(JSON.parse(location?.state?.payout_history))
            fetchGlobalSettings()
        }
    }, [location?.state?.payout_history])

    const fetchGlobalSettings = async () => {
        try {
            const response = (await getAllGlobalSettings({ search: 'payout_percentage' })).data
            setPayoutPercentage(response.data[0].payload)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className="page-content">
            <div className="rent_history_tab rent_slips_tab rent_slips_detail_tab">
                <div className='complain_ticket'>
                    <div className='overall_tickets list-inline'>
                        {payoutHistoryDetails && (
                            <div className='single_ticket'>
                                <div className='single_ticket_header d-flex justify-content-between align-items-center' style={{ cursor: 'pointer' }}>
                                    <div className='ticket_id'>
                                        <ArrowBackOutlinedIcon style={{ color: '#1C1B1F', cursor: 'pointer' }} onClick={() => {
                                            sessionStorage.setItem('selectedTab', 2)
                                            navigate(-1)
                                        }} />
                                        <b>{dayjs(payoutHistoryDetails?.payment?.payment_date).format('DD-MM-YYYY')}</b></div>
                                    <div className="rs_header_right d-flex">
                                        <div className='ticket_id me-3'><b>Total: ₹{payoutHistoryDetails?.total_payout_amount}</b></div> 
                                    </div>
                                </div>
                                <div className='single_ticket_body'>
                                    <div className="d-grid three_grid_cols">
                                        <div className='create_date bordered_block'>
                                            <div className='responsive_word_break'><CalendarTodayOutlinedIcon /> Payment Tenure:</div> <b>{payoutHistoryDetails?.start_date ? `${dayjs(payoutHistoryDetails?.start_date).format('DD-MM-YYYY')} to ${dayjs(payoutHistoryDetails?.end_date).format('DD-MM-YYYY')}` : 'N/A'} </b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                            <div className='responsive_word_break'><SyncAltOutlinedIcon />Transaction Id:</div> <b>{payoutHistoryDetails?.payment?.transaction_id}</b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                            <div className='responsive_word_break'><CreditScoreOutlinedIcon />Payout Account No.:</div> <b>{payoutHistoryDetails?.user?.property_owner?.bank_detail?.account_number}</b>
                                        </div>
                                    </div>
                                    <div className='resolved_by admin_comment_border d-flex'>
                                        <div className='responsive_word_break'><PersonOutlinedIcon />Admin Comment:</div> <b>{payoutHistoryDetails?.note?.description}</b>
                                    </div>
                                </div>
                            </div>
                        )}
                        {payoutHistoryDetails?.tenant_rent_slips?.length > 0 && payoutHistoryDetails?.tenant_rent_slips.map((item, index) => (
                            <div className="ph_inner_detail_listing" key={index}>
                            <div className='single_ticket'>
                                <div className='single_ticket_header d-flex justify-content-between align-items-center'>
                                        <div className='ticket_id'><b>{item?.tenant?.user?.name}</b></div>
                                    <div className="rs_header_right d-flex">
                                            <div className='ticket_id me-3'><b>Total: ₹{item?.total_amount}</b></div> 
                                    </div>
                                </div>
                                <div className='single_ticket_body'>
                                    <div className="d-grid custom_five_grid_cols">
                                        <div className='create_date bordered_block'>
                                                <div className='responsive_word_break'><BusinessOutlinedIcon /> Floor No:</div> <b>{item?.floor?.name}</b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                                <div className='responsive_word_break'><HomeWorkOutlinedIcon />Flat No:</div> <b>{item?.flat?.name}</b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                                <div className='responsive_word_break'><HomeOutlinedIcon />Room:</div> <b>{item?.room?.name}</b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                                <div className='responsive_word_break'><PaymentsOutlinedIcon />Rent:</div> <b>₹{item?.rent}</b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                                <div className='responsive_word_break'><EmojiObjectsOutlinedIcon />Amount of Electricity:</div> <b>₹{item?.electricity_amount}</b>
                                            </div>
                                            <div className='resolved_by bordered_block'>
                                                <div className='responsive_word_break'><EmojiObjectsOutlinedIcon />Other Amount:</div> <b>₹{item?.other_amount}</b>
                                            </div>
                                            <div className='resolved_by bordered_block'>
                                                <div className='responsive_word_break'><EmojiObjectsOutlinedIcon />Payout Percentage:</div> <b>{item?.tenant?.custom_payout_percentage > 0 ? item?.tenant?.custom_payout_percentage : payoutPercentage?.value}%</b>
                                            </div>
                                    </div>
                                    <div className='resolved_by admin_comment_border d-flex'>
                                            <div className='responsive_word_break'><PersonOutlinedIcon />Comment:</div> <b>{item?.comment_for_owner}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}