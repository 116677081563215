import React, { useEffect, useState } from 'react';
import { Button, Modal, Row } from 'reactstrap';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
// import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
// import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import * as Yup from 'yup';
import uuid from 'react-native-uuid';
import { ThemeProvider } from '@emotion/react';
import { Autocomplete, Box, createTheme } from '@mui/material';
// import { useProfile } from '../Hooks/UserHooks';
import { ACTIONS, DOCUMENT_HASH_BASE_KEYS, PROPERTY_TYPES } from '../../common/constant';
import Dropzone from 'react-dropzone';
import { formatBytes } from '../../helpers/string_helper';
import FileList from '../Common/FileList';
import ConfirmationModal from '../Common/ConfirmationModal';
import { documentUpload } from '../../api/documents';
import { createPropertyRequest, getAllNearTo, updatePropertyRequest } from '../../api/new-property-request';
// import { NearToDropDownPaginationList } from '../Common/NearToDropDownPaginationList';
import { selectAmenitiesIcon } from '../../common/amenities_icon';
import AddAmenitiesSelector from '../Common/addAmenitiesSelector';
// import { ACTIONS, ALL_MODULES } from '../../common/constant';
// import { updateSchedulePropertyVisitRequestByAdmin } from '../../api/schedule-property-visit';

export default function AddEditPropertyRequest({ isOpen, handleClose, refresh, owner_user_id, editData, action }) {
  const MAX_FILE_SIZE = 5; // in MB
  const [selectedFiles, setselectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [isCreateConfirm, setCreateConfirm] = useState({
    status: false,
    data: null,
  });
  const [isEditConfirm, setEditConfirm] = useState({
    status: false,
    id: null,
    data: null,
  });
  const [isDocumentDeleteConfirm, setIsDocumentDeleteConfirm] = useState({
    status: false,
    data: null,
  });
  const [nearToOptions, setNearToOptions] = useState([]);

  const propertyRequestSchema = {
    name: editData?.name ?? '',
    owner_user_id,
    type: editData?.type ?? '',
    landmark: editData?.landmark ?? '',
    amenities: editData?.amenities?.length > 0 ? editData.amenities?.map((am) => {
      return { name: am, icon: selectAmenitiesIcon(am.toLowerCase()) }
    }) : '',
    near_to: editData?.near_to ?? '',
    room_count: editData?.room_count ?? '',
    documents: editData?.documents ?? [],
    total_documents_size: editData?.total_documents_size,
    address: {
      address_line_1: editData?.address?.address_line_1 ?? '',
      address_line_2: editData?.address?.address_line_2 ?? '',
      city: editData?.address?.city ?? '',
      district: editData?.address?.district ?? '',
      state: editData?.address?.state ?? '',
      country: editData?.address?.country ?? '',
      zip_code: editData?.address?.zip_code ?? '',
    },
  };

  function confirmFileRemoval(e, file, fileIndex) {
    setIsDocumentDeleteConfirm({
      status: true,
      data: { e, file, fileIndex }
    })
  }

  const propertyRequestValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Property Name"),
    type: Yup.string().required('Please select a Property type'),
    near_to: Yup.string().optional('Please Enter Near To Location'),
    room_count: Yup.number().required('Please Enter Room Count'),
    documents: Yup.array()
      .max(5, 'You are only allowed to have 5 documents here.').optional()
      .of(
        Yup.mixed()
          .required("Required")
          .test("is-valid-size", "Max allowed size is 5MB",
            value => {
              console.log({ value });
              return value && value.size <= MAX_FILE_SIZE * 1024 * 1024
            })
      ),
    total_documents_size: Yup.number()
      .positive()
      .when('documents', {
        is: (docs) => docs?.length > 0,
        then: (schema) =>
          schema
            .min(1, 'The documents size must be greater than 1 Byte.')
            .max(25 * 1024 * 1024, 'The documents size must not exceed 25 MiB.')
            .required(),
        otherwise: (schema) => schema.optional(),
      }),
    address: Yup.object().shape({
      address_line_1: Yup.string().required('Please Enter Address Line 1'),
      address_line_2: Yup.string().optional('Please Enter Address Line 2'),
      city: Yup.string().required('Please Enter City'),
      district: Yup.string().required('Please Enter District'),
      state: Yup.string().required('Please Enter State'),
      country: Yup.string().required('Please Enter Country'),
      zip_code: Yup.number().required('Please Enter Zip Code'),
    })
  });
  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: propertyRequestSchema,
    validationSchema: propertyRequestValidationSchema,
    validateOnMount: true,
    onSubmit: async (propertyRequestData) => {
      action === ACTIONS.EDIT
        ? setEditConfirm({
          status: true,
          id: editData?.id,
          data: propertyRequestData,
        })
        : setCreateConfirm({ status: true, data: propertyRequestData });
    },
  });

  const uploadDocuments = async (formData) => {
    try {
      const response = await documentUpload(formData);
      if (response.status === 200) {
        return { response, success: true };
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
      return { error, success: false };
    }
  };

  const createPropertyRequestHandler = async (propertyRequestData) => {
    try {
      const response = await createPropertyRequest(propertyRequestData);
      if (response.status === 200) {
        toast.success(response.data.message);
        return true;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error.message);
      console.log(error);
    }
    return false;
  };

  const updatePropertyRequestHandler = async (id, propertyRequestData) => {

    try {
      const response = await updatePropertyRequest(id, propertyRequestData);
      if (response.status === 200) {
        toast.success(response.data.message);
        return true;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error.message);
      console.log(error);
    }
    return false;
  };
  const fetchAllNearTo = async (setNearToOptions) => {
    try {
      const res = (await getAllNearTo()).data.data
      const result = res?.data.map(item => item.name);
      setNearToOptions(result);
    } catch (error) {
      if (error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error.message);
      console.log(error);
    }
  }

  let isSuccess = null;
  const submitHandler = async (propertyRequestData, id) => {
    setIsLoading(true);
    let documents
    const filesUnchanged = formik.values?.documents?.filter((file) => file?.id) ?? []
    if (filesUnchanged?.length > 0) {
      documents = [...filesUnchanged.map(sd => sd.id)]
    }

    const filesToBeUploaded = selectedFiles.filter((file) => !file?.id)
    if (filesToBeUploaded?.length > 0) {
      const formData = new FormData();
      filesToBeUploaded.forEach((f) => {
        formData.append('files', f)
      })
      formData.append('base_key', DOCUMENT_HASH_BASE_KEYS.PROPERTY_REQUEST)
      formData.append('max_items', 10)

      const result = await uploadDocuments(formData)
      if (result.success) {
        const { response } = result

        if (documents !== undefined) {
          documents = [...documents, ...response.data.data.documents.map((doc) => doc.id)]
        } else {
          documents = [...response.data.data.documents.map((doc) => doc.id)]
        }

      } else {
        setIsLoading(false);
        return false;
      }
    }
    // removing empty fields
    propertyRequestData = {
      ...(propertyRequestData.name && {
        name: propertyRequestData.name,
      }),
      ...(propertyRequestData.owner_user_id && {
        owner_user_id: propertyRequestData.owner_user_id,
      }),
      ...(propertyRequestData.amenities && {
        amenities: propertyRequestData.amenities?.map((item) => item.name),
      }),
      ...(propertyRequestData.type && {
        type: propertyRequestData.type,
      }),
      ...(propertyRequestData.near_to && {
        near_to: propertyRequestData.near_to.toString(),
      }),
      ...(propertyRequestData.landmark && {
        landmark: propertyRequestData.landmark,
      }),
      ...(propertyRequestData.room_count && {
        room_count: propertyRequestData.room_count,
      }),
      ...(propertyRequestData.address && {
        address: {
          ...(propertyRequestData.address.address_line_1 && {
            address_line_1: propertyRequestData.address.address_line_1,
          }),
          ...(propertyRequestData.address.address_line_2 && {
            address_line_2: propertyRequestData.address.address_line_2,
          }),
          ...(propertyRequestData.address.city && {
            city: propertyRequestData.address.city,
          }),
          ...(propertyRequestData.address.district && {
            district: propertyRequestData.address.district,
          }),
          ...(propertyRequestData.address.state && {
            state: propertyRequestData.address.state,
          }),
          ...(propertyRequestData.address.country && {
            country: propertyRequestData.address.country,
          }),
          ...(propertyRequestData.address.zip_code && {
            zip_code: propertyRequestData.address.zip_code,
          }),
        },
      }),
      ...(action === ACTIONS.EDIT ? documents === undefined ? { documents: [] } : { documents } : documents === undefined ? {} : { documents })
    };
    isSuccess = action === ACTIONS.EDIT
      ? await updatePropertyRequestHandler(id, propertyRequestData)
      : await createPropertyRequestHandler(propertyRequestData);
    setIsLoading(false);
    return isSuccess;
  };

  const handleAddAmenities = (data) => {
    formik.setValues({
      ...formik.values,
      amenities: data
    })
  }

  const handleFormikAmenities = (itemName) => {
    const updatedAmenities = formik.values.amenities?.filter((it) => it.name !== itemName.name)
    formik.setValues({
      ...formik.values,
      amenities: updatedAmenities
    })
  }

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        uniqueId: uuid.v4(),
        location: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    formik.setValues({
      ...formik.values,
      documents: [...selectedFiles, ...files],
      total_documents_size: [...selectedFiles, ...files].reduce((p, c) => p + c.size, 0)
    }, true).then(() => {
      setselectedFiles((prev) => {
        return [...prev, ...files]
      })
    });
  }

  function handleFileRemove(e, file, fileIndex) {
    const newDocuments = formik.values.documents.filter((doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId))
    const tempValues = {
      ...formik.values
    }

    if (newDocuments.length === 0) {
      delete tempValues.documents
      delete tempValues.total_documents_size
    }
    formik.setValues({
      ...tempValues,
      ...(
        newDocuments.length > 0 && {
          documents: newDocuments,
        }
      ),
      ...(
        newDocuments.length > 0 && {
          total_documents_size: selectedFiles.filter((doc, i) => !(i === fileIndex && doc.uniqueId === file.uniqueId)).reduce((p, c) => p + c.size, 0)
        }
      ),
    }, true).then(() => {
      setselectedFiles(prev => prev.filter((_p, i) => !(i === fileIndex && _p.uniqueId === file.uniqueId)))
    });

    return true
  }

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: 'red', // Change the color here
          },
        },
      },
    },
  });

  useEffect(() => {
    // fetching all near to location
    fetchAllNearTo(setNearToOptions)

    if (editData?.documents?.length > 0) {
      const newDocuments = editData?.documents?.map((doc) => ({ ...doc, uniqueId: uuid.v4(), formattedSize: formatBytes(doc.size) }))
      formik.setValues({
        ...formik.values,
        ...(
          newDocuments.length > 0 &&
          {
            documents: [...newDocuments]
          }
        ),
        ...(
          newDocuments.length > 0 &&
          {
            total_documents_size: [...newDocuments].reduce((p, c) => p + c.size, 0)
          }
        )
      }, true).then(() => {
        setselectedFiles([...newDocuments])
      })
    }
  }, [])

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={handleClose}
        backdrop='static'
        centered
        fullscreen='md'
        size='md'
        scrollable
        style={{ maxWidth: '600px', width: '600px' }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {action === ACTIONS.EDIT ? 'Edit Property Request' : 'Add Property Request'}
          </h5>
          <button
            type="button"
            onClick={() => {
              formik.resetForm();
              handleClose();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            {/* Name */}
            <div className='col-sm-12 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='Name'
                  size='small'
                  name='name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.name || ''}
                  error={!!(formik?.touched?.name && formik?.errors?.name)}
                  helperText={formik?.touched?.name && formik?.errors?.name ? formik?.errors?.name : ''}
                  required
                />
              </ThemeProvider>
            </div>

            {/* Property Type */}
            <div className='col-sm-6 mb-3'>
              <Autocomplete
                name='type'
                size='small'
                value={formik.values?.type}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    'type',
                    newValue ? newValue : ''
                  );
                }}
                onBlur={() => {
                  if (!formik.touched?.type) {
                    formik.setFieldTouched('type', true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' key={option} {...props}>
                    {option}
                  </Box>
                )}
                isOptionEqualToValue={(option, value) => option === value}
                options={Object.values(PROPERTY_TYPES)}
                renderInput={(params) => {
                  return (
                    <ThemeProvider theme={theme}>
                      <TextField
                        {...params}
                        onBlur={formik.handleBlur}
                        error={!!(formik?.touched?.type && formik?.errors?.type)}
                        helperText={(formik?.touched?.type && formik?.errors?.type) ? formik?.errors?.type : ''}
                        label='Select Property Type'
                        size='small'
                        required
                      />
                    </ThemeProvider>
                  );
                }}
              />
            </div>

            {/* Near To */}
            <div className="col-sm-6 mb-3">
              <Autocomplete
                autoHighlight
                name='near_to'
                size='small'
                value={formik.values?.near_to}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    'near_to',
                    newValue ? newValue : ''
                  );
                }}
                onBlur={() => {
                  if (!formik.touched?.near_to) {
                    formik.setFieldTouched('near_to', true, true);
                  }
                }}
                renderOption={(props, option) => (
                  <Box component='li' key={option} {...props}>
                    {option}
                  </Box>
                )}
                isOptionEqualToValue={(option, value) => option === value}
                options={nearToOptions}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      onBlur={formik.handleBlur}
                      error={!!(formik?.touched?.near_to && formik?.errors?.near_to)}
                      helperText={(formik?.touched?.near_to && formik?.errors?.near_to) ? formik?.errors?.near_to : ''}
                      label='Near To'
                      size='small'
                    />
                  );
                }}
              />
              {/* <NearToDropDownPaginationList
                defaultValue={[formik?.values?.near_to]}
                disabled={isLoading}
                searchEnabled={true}
                name="near_to"
                listCall={getAllNearTo}
                emitItem={(newValue) => {
                  formik.setFieldValue('near_to', newValue ? newValue : '')
                }}
                apiParams={{ limit: 10 }}
              /> */}
              <div>
                {formik.touched.near_to && formik.errors.near_to && (
                  <span className="text-danger">{formik.errors.near_to}</span>
                )}
              </div>
            </div>

            {/* Landmark */}
            <div className='col-sm-6 mb-3'>
              <TextField
                className='form-control'
                type='text'
                label='Landmark'
                name='landmark'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.landmark || ''}
                error={
                  !!(formik?.touched?.landmark && formik?.errors?.landmark)
                }
                helperText={
                  formik?.touched?.landmark && formik?.errors?.landmark
                    ? formik?.errors?.landmark
                    : ''
                }
                size='small'
              />
            </div>

            {/* Room Count */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  size='small'
                  className='form-control'
                  type='text'
                  label='Room Count'
                  name='room_count'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.room_count}
                  error={!!(formik?.touched?.room_count && formik?.errors?.room_count)}
                  helperText={(formik?.touched?.room_count && formik?.errors?.room_count) ? formik?.errors?.room_count : ''}
                  required
                />
              </ThemeProvider>
            </div>

            {/* Address Line 1 */}
            <div className='col-sm-12 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='Address Line 1'
                  name='address.address_line_1'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.address?.address_line_1 || ''}
                  error={
                    !!(formik?.touched?.address?.address_line_1 && formik?.errors?.address?.address_line_1)
                  }
                  helperText={
                    formik?.touched?.address?.address_line_1 && formik?.errors?.address?.address_line_1
                      ? formik?.errors?.address?.address_line_1
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* Address Line 2 */}
            <div className='col-sm-12 mb-3'>
              <TextField
                className='form-control'
                type='text'
                label='Address Line 2'
                name='address.address_line_2'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.address?.address_line_2 || ''}
                error={
                  !!(formik?.touched?.address?.address_line_2 && formik?.errors?.address?.address_line_2)
                }
                helperText={
                  formik?.touched?.address?.address_line_2 && formik?.errors?.address?.address_line_2
                    ? formik?.errors?.address?.address_line_2
                    : ''
                }
                size='small'
              />
            </div>

            {/* City */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='City'
                  name='address.city'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.address?.city || ''}
                  error={
                    !!(formik?.touched?.address?.city && formik?.errors?.address?.city)
                  }
                  helperText={
                    formik?.touched?.address?.city && formik?.errors?.address?.city
                      ? formik?.errors?.address?.city
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* District */}
            <div className='col-sm-6 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='District'
                  name='address.district'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.address?.district || ''}
                  error={
                    !!(formik?.touched?.address?.district && formik?.errors?.address?.district)
                  }
                  helperText={
                    formik?.touched?.address?.district && formik?.errors?.address?.district
                      ? formik?.errors?.address?.district
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* State */}
            <div className='col-sm-4 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='State'
                  name='address.state'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.address?.state || ''}
                  error={
                    !!(formik?.touched?.address?.state && formik?.errors?.address?.state)
                  }
                  helperText={
                    formik?.touched?.address?.state && formik?.errors?.address?.state
                      ? formik?.errors?.address?.state
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* Country */}
            <div className='col-sm-4 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='Country'
                  name='address.country'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.address?.country || ''}
                  error={
                    !!(formik?.touched?.address?.country && formik?.errors?.address?.country)
                  }
                  helperText={
                    formik?.touched?.address?.country && formik?.errors?.address?.country
                      ? formik?.errors?.address?.country
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* Zip Code */}
            <div className='col-sm-4 mb-3'>
              <ThemeProvider theme={theme}>
                <TextField
                  className='form-control'
                  type='text'
                  label='Zip Code'
                  name='address.zip_code'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.address?.zip_code || ''}
                  error={
                    !!(formik?.touched?.address?.zip_code && formik?.errors?.address?.zip_code)
                  }
                  helperText={
                    formik?.touched?.address?.zip_code && formik?.errors?.address?.zip_code
                      ? formik?.errors?.address?.zip_code
                      : ''
                  }
                  size='small'
                  required
                />
              </ThemeProvider>
            </div>

            {/* Amenities */}
            <div className="single_input_block">
              <AddAmenitiesSelector handleAddAmenities={handleAddAmenities} selectedAmenities={formik.values?.amenities} handleFormikAmenities={handleFormikAmenities} />
            </div>
            {/* {isAmenitiesModalOpen && <AddAmenitiesModal open={isAmenitiesModalOpen} handleClose={() => setIsAmenitiesModalOpen(false)} handleAddAmenities={handleAddAmenities} selectedAmenities={formik.values?.amenities} />} */}

            {/* Documents */}
            <div className='col-12'>
              <label>Upload Documents</label>
              <Dropzone
                accept={{
                  'image/jpeg': [],
                  'image/png': [],
                  'image/jpg': []
                }}
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                }}>
                {({ getRootProps, getInputProps }) => (
                  <div className='dropzone' style={{
                    background: '#f0fcff',
                    marginBottom: '10px',
                    border: '2px dashed #69ceee',
                    borderRadius: '15px',
                  }}>
                    <div className='dz-message needsclick' {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className='mb-3'>
                        <i className='display-4 text-muted uil uil-cloud-upload' />
                      </div>
                      <h4>Drop files here or click to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className='col-12 my-3'>
                {(formik.errors.documents || formik.errors.total_documents_size) &&
                  <ul>
                    {formik.errors.documents && <li className='text-danger'>{formik.errors.documents}</li>}
                    {formik.errors.total_documents_size && <li className='text-danger'>{formik.errors.total_documents_size}</li>}
                  </ul>}
              </div>

              {
                selectedFiles.length > 0 && (
                  <FileList files={selectedFiles} confirmFileRemoval={confirmFileRemoval} />
                )
              }
            </div>
          </Row>
        </div>

        <div className="modal-footer justify-content-start" style={{ borderTop: '1px solid #ddd' }}>
          <Button
            type="button"
            onClick={formik.handleSubmit}
            className={`btn ${isLoading || !formik.isValid ? 'theme_button sign_in_button' : 'theme_button sign_in_button'} waves-effect waves-light`}
            data-dismiss="modal"
            size='small'
            style={{ cursor: isLoading || !formik.isValid ? 'not-allowed' : 'pointer' }}
            disabled={isLoading || !formik.isValid}
          >
            {action === ACTIONS.EDIT ? 'Save & Continue' : 'Add Property'}
          </Button>
        </div>
      </Modal>
      {/* Create confirmation modal */}
      {isCreateConfirm?.status &&
        <ConfirmationModal
          action={ACTIONS.CREATE}
          show={isCreateConfirm?.status}
          onCloseClick={() => setCreateConfirm({ status: false, data: null })}
          onAcceptClick={async () => {
            const isCreated = await submitHandler(isCreateConfirm?.data);
            if (isCreated) {
              refresh();
            }
            setCreateConfirm({ status: false, id: null });
            formik.resetForm();
            handleClose();
          }}
          isDisabled={isLoading}
        />}

      {/* Edit confirmation modal */}
      {isEditConfirm?.status &&
        <ConfirmationModal
          action={ACTIONS.EDIT}
          show={isEditConfirm?.status}
          onCloseClick={() =>
            setEditConfirm({ status: false, id: null, data: null })
          }
          onAcceptClick={async () => {
            const isUpdated = await submitHandler(
              isEditConfirm?.data,
              isEditConfirm?.id,
            );
            if (isUpdated && isSuccess) {
              await refresh();
            }
            setEditConfirm({ status: false, id: null });
            formik.resetForm();
            handleClose();
          }}
          isDisabled={isLoading}
        />}

      {/* Document Delete confirmation modal */}
      {isDocumentDeleteConfirm?.status &&
        <ConfirmationModal
          action={ACTIONS.DELETE}
          show={isDocumentDeleteConfirm?.status}
          onCloseClick={() =>
            setIsDocumentDeleteConfirm({ status: false, data: null })
          }
          onAcceptClick={async () => {
            const { e, file, fileIndex } = isDocumentDeleteConfirm.data
            const isDeleted = handleFileRemove(
              e, file, fileIndex
            );
            if (isDeleted) {
              setIsDocumentDeleteConfirm({ status: false, data: null });
            }
          }}
          isDisabled={isLoading}
        />}
    </div>
  );
}

